import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { faHome, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Table,
  Card,
  Button,
  Form,
  Breadcrumb,
  InputGroup,
} from "@themesberg/react-bootstrap";

import services from "../../services/index";

export default () => {
  let history = useHistory();

  function handleNav(path) {
    history.push(path);
  }

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [moduleUnits, setModuleUnits] = useState([]);
  const [learnerId, setLearnerId] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCentre, setSelectedCentre] = useState(0);
  const [centreOptions, setCentreOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [courseOptions, setCourseOptions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [saveValid, setSaveValid] = useState(false);
  const [updateID, setUpdateID] = useState(null);

  function handleChange(e, type, index = 0) {
    switch (type) {
      case "lname":
        let lname = e && e.target?.value ? e.target.value : "";
        setLName(lname);
        break;

      case "fname":
        let fname = e && e.target?.value ? e.target.value : "";
        setFName(fname);
        break;

      case "learner_id":
        let specification = e && e.target?.value ? e.target.value : "";
        setLearnerId(specification);
        break;

      case "country":
        let countryID = e && e.target?.value ? e.target.value : 0;

        const resultCountry = countryOptions.find(
          (country) => country.ID === parseInt(countryID)
        );

        if (resultCountry) {
          setSelectedCountry(countryID);
        } else {
          setSelectedCountry(0);
        }
        break;

      case "centre":
        let centreID = e && e.target?.value ? e.target.value : 0;

        const resultCentre = centreOptions.find(
          (centre) => centre.ID === parseInt(centreID)
        );

        if (resultCentre) {
          setSelectedCentre(centreID);
          setCourseOptions(
            resultCentre && resultCentre.courses ? resultCentre.courses : []
          );
        } else {
          setSelectedCentre(0);
          setCourseOptions([]);
        }
        break;

      case "course":
        let courseID = e && e.target?.value ? e.target.value : 0;

        const resultCourse = courseOptions.find(
          (course) => course.ID === parseInt(courseID)
        );

        if (resultCourse) {
          setSelectedCourse(courseID);
        } else {
          setSelectedCourse(0);
        }
        break;
      default:
        break;
    }
  }

  function validateForm() {
    let formsValid = false;
    formsValid = learnerId && fName && lName && selectedCountry && selectedCentre &&  courses && courses.length > 0 ? true : false;
    setSaveValid(formsValid);
    return formsValid;
  }

  function validateModules() {
    let modulesValid = true;
    for (let index = 0; index < moduleUnits.length; index++) {
      const element = moduleUnits[index];
      let valid =
        element.unitref &&
        element.title &&
        element.title &&
        element.level &&
        element.credits &&
        element.glh
          ? true
          : false;

      if (!valid) {
        modulesValid = false;
        break;
      }
    }
    return modulesValid;
  }

  async function saveCertificate() {
    try {
      let status = validateForm();

      if (!status) {
        return;
      }

      let data = {
        first_name: fName,
        last_name: lName,
        learner_id: parseInt(learnerId),
        country_id: parseInt(selectedCountry),
        centre_id: parseInt(selectedCentre),
        courses: courses,
        status: 1,
      };

      if (cid) {
        data.ID = updateID;
        const req = await services.studentService.putStudent(data);
        if (req) {
          toast.success("Student updated");
        }
      } else {
        const req = await services.studentService.postCreateStudent(data);
        if (req) {
          toast.success("Student Created");
          history.push("/student-management");
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  }

  async function fetchCountryList(params) {
    const req = await services.commonService.getCountryList();
    setCountryOptions(req?.data?.data ? req.data.data : []);
  }

  async function fetchCentreList(params) {
    const req = await services.centreService.getCentres({
      limit: 100,
      page: 1,
    });
    setCentreOptions(req?.data?.data ? req.data.data : []);
  }

  async function fetchData(id) {
    const req = await services.studentService.getStudent(id);
    if (req?.data?.data) {
      let res = req.data.data;

      setFName(res?.first_name ? res.first_name : "");
      // setSelectedCategory(res?.category_id ? res.category_id : 0);
      setLName(res?.last_name ? res.last_name : "");
      setLearnerId(res?.learner_id ? res.learner_id : "");
      setSelectedCountry(res?.country_id ? res.country_id : 0);
      setSelectedCentre(res?.centre_id ? res.centre_id : 0);
      setUpdateID(res?.ID ? res.ID : null);
      setCourses(res?.courses ? res.courses : []);
    }
  }

  const details_handler = (event, index) => {
    const items = [...moduleUnits];
    console.log(event.target.type);
    items[index][event.target.name] =
      event.target.type === "number"
        ? parseInt(event.target.value)
        : event.target.value;
    console.log(items);
    setModuleUnits(items);
  };

  function deleteModule(index) {
    const items = [...moduleUnits];
    if (items[index] && items[index].ID) {
      items[index].is_del = 1;
    } else {
      items.splice(index, 1);
    }
    setModuleUnits(items);
  }

  function assignCourse() {
    const coursesData = [...courses];
    if (selectedCourse) {
      const isAvail = coursesData.find(
        (course) => course.ID === parseInt(selectedCourse)
      );
      console.log(isAvail);
      if (isAvail && isAvail.ID) {
        return;
      }

      const resultCourse = courseOptions.find(
        (course) => course.ID === parseInt(selectedCourse)
      );
      coursesData.push(resultCourse);
      setCourses(coursesData);
      console.log(resultCourse);
    }
  }

  function addNewModule() {
    const items = [...moduleUnits];
    items.push({
      unitref: "",
      title: "",
      level: 0,
      credits: 0,
      glh: 0,
      tqt: 0,
    });
    setModuleUnits(items);
  }

  useEffect(() => {
    (async () => {
      fetchCountryList();
      fetchCentreList();
      if (cid) {
        await fetchData(cid);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = useLocation().search;
  const cid = new URLSearchParams(search).get("id");

  useEffect(() => {
    (async () => {
      validateForm();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerId, lName, selectedCountry, selectedCentre, courses, fName]);

  useEffect(() => {
    (async () => {
      if (cid && centreOptions.length && selectedCentre) {
        const resultCentre = centreOptions.find(
          (centre) => centre.ID === parseInt(selectedCentre)
        );
        setCourseOptions(
          resultCentre && resultCentre.courses ? resultCentre.courses : []
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreOptions, selectedCentre]);

  return (
    <>
      <ToastContainer />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => handleNav("/student-management")}>
              Student Management
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {cid ? "Edit" : "New"} Student
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{cid ? "Edit" : "New"} Student</h4>
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="courseName">
                      <Form.Label>First Name</Form.Label>
                      <InputGroup>
                        {/* <InputGroup.Text>C</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          value={fName}
                          onChange={(e) => handleChange(e, "fname")}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="courseName">
                      <Form.Label>Last Name</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          value={lName}
                          onChange={(e) => handleChange(e, "lname")}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6} className="mb-3">
                    <Form.Group id="courseName">
                      <Form.Label>Learner Id</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          placeholder="XXXX"
                          value={learnerId}
                          onChange={(e) => handleChange(e, "learner_id")}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Country</Form.Label>
                      <Form.Select
                        disabled={countryOptions.length === 0}
                        id="state"
                        value={selectedCountry}
                        onChange={(e) => handleChange(e, "country")}
                      >
                        <option value="0" key={0}>
                          -- Select --
                        </option>
                        {countryOptions.map((data) => (
                          <option value={data.ID} key={data.ID}>
                            {data.country}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Centre</Form.Label>
                      <Form.Select
                        disabled={centreOptions.length === 0}
                        id="state"
                        value={selectedCentre}
                        onChange={(e) => handleChange(e, "centre")}
                      >
                        <option value="0" key={0}>
                          -- Select --
                        </option>
                        {centreOptions.map((data) => (
                          <option value={data.ID} key={data.ID}>
                            {data.name +
                              " - " +
                              (data?.country?.country
                                ? data.country.country
                                : "")}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <h5 className="my-4">Courses</h5>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Select
                        disabled={selectedCentre.length === 0}
                        id="state"
                        value={selectedCourse}
                        onChange={(e) => handleChange(e, "course")}
                      >
                        <option value="0" key={0}>
                          -- Select --
                        </option>
                        {courseOptions.map((data) => (
                          <option value={data.ID} key={data.ID}>
                            {data.name + " - " + data.specification}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Button
                        disabled={selectedCentre === 0}
                        onClick={() => assignCourse()}
                        variant="warning"
                      >
                        Assign Course
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col sm={12} className="mb-3">
                    <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">ID</th>
                          <th className="border-0">Course Name</th>
                          <th className="border-0">Specification</th>
                          <th className="border-0">Qualification Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courses.map((r, index) => {
                          return (
                            !r.is_del && (
                              <tr key={index}>
                                <td className="fw-bold border-0">{r.ID}</td>
                                <td className="fw-bold border-0">{r.name}</td>
                                <td className="border-0">{r.specification}</td>
                                <td className="border-0">
                                  {r.qualification_number}
                                </td>

                                <td className="fw-bold border-0">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="text-danger"
                                  >
                                    <FontAwesomeIcon
                                      onClick={() => deleteModule(index)}
                                      icon={faTrashAlt}
                                      className="me-3 mt-3"
                                    />
                                  </span>
                                </td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {/* <Button
                  disabled={!validateModules()}
                  onClick={() => addNewModule()}
                  variant="outline-primary"
                  style={{ width: "150px", float: "right" }}
                  className="m-1"
                >
                  Add New Module
                </Button> */}
                <div className="mt-4">
                  {/* {birthday} */}
                  <Button
                    disabled={!saveValid}
                    onClick={() => saveCertificate()}
                    variant="primary"
                  >
                    Save Course
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { faHome, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Table,
  Card,
  Button,
  Form,
  Breadcrumb,
  InputGroup,
} from "@themesberg/react-bootstrap";

import services from "../../services/index";

export default () => {
  let history = useHistory();

  function handleNav(path) {
    history.push(path);
  }

  const [courseName, setCourseName] = useState("");
  const [centerTypeOptions, setCenterTypesOptions] = useState([]);
  const [selectedCenterType, setSelectedCenterType] = useState(0);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [courseOptions, setCourseOptions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [saveValid, setSaveValid] = useState(false);
  const [updateID, setUpdateID] = useState(null);

  function handleChange(e, type, index = 0) {
    switch (type) {
      case "country":
        let countryID = e && e.target?.value ? e.target.value : 0;

        const resultCountry = countryOptions.find(
          (country) => country.ID === parseInt(countryID)
        );

        if (resultCountry) {
          setSelectedCountry(countryID);
        } else {
          setSelectedCountry(0);
        }
        break;

      case "centerType":
        let centerTypeID = e && e.target?.value ? e.target.value : 0;

        const result = centerTypeOptions.find(
          (centerType) => centerType.ID === parseInt(centerTypeID)
        );

        if (result) {
          setSelectedCenterType(centerTypeID);
        } else {
          setSelectedCenterType(0);
        }
        break;

      case "course":
        let courseID = e && e.target?.value ? e.target.value : 0;

        const resultCourse = courseOptions.find(
          (course) => course.ID === parseInt(courseID)
        );

        if (resultCourse) {
          setSelectedCourse(courseID);
        } else {
          setSelectedCourse(0);
        }
        break;

      case "course_name":
        let course_name = e && e.target?.value ? e.target.value : "";
        setCourseName(course_name);
        break;

      default:
        break;
    }
  }

  function validateForm() {
    let formsValid = false;
    formsValid =
      courseName && selectedCenterType && selectedCountry ? true : false;
    setSaveValid(formsValid);
    return formsValid;
  }

  async function saveCentre() {
    try {
      let status = validateForm();

      if (!status) {
        return;
      }

      if (cid) {
        let data = {
          ID: updateID,
          name: courseName,
          centretype_id: parseInt(selectedCenterType),
          country_id: parseInt(selectedCountry),
          status: 1,
          courses: courses,
        };

        const req = await services.centreService.putCentre(data);
        if (req) {
          toast.success("Centre updated");
        }
      } else {
        let data = {
          name: courseName,
          centretype_id: parseInt(selectedCenterType),
          country_id: parseInt(selectedCountry),
          status: 1,
          courses: courses,
        };

        const req = await services.centreService.postCreateCentre(data);
        if (req) {
          toast.success("Centre Created");
          history.push("/centre-management");
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  }

  async function fetchCategoryList(params) {
    const req = await services.commonService.getCenterTypesList();
    setCenterTypesOptions(req?.data?.data ? req.data.data : []);
  }

  async function fetchCountryList(params) {
    const req = await services.commonService.getCountryList();
    setCountryOptions(req?.data?.data ? req.data.data : []);
  }

  async function fetchData(id) {
    const req = await services.centreService.getCentre(id);
    if (req?.data?.data) {
      let res = req.data.data;
      setCourseName(res?.name ? res.name : "");
      setSelectedCenterType(res?.centretype_id ? res.centretype_id : 0);
      setSelectedCountry(res?.country_id ? res.country_id : 0);
      setUpdateID(res?.ID ? res.ID : null);
      setCourses(res?.courses ? res.courses : []);
    }
  }

  async function fetchCourseList(params) {
    const req = await services.courseService.getCourses(params);
    setCourseOptions(req?.data?.data ? req.data.data : []);
  }

  function details_handler(params) {}

  function deleteModule(index) {
    // const items = [...moduleUnits];
    // if (items[index] && items[index].ID) {
    // items[index].is_del = 1;
    // } else {
    //   items.splice(index, 1);
    // }
    // setModuleUnits(items);
  }

  function assignCourse() {
    const coursesData = [...courses];
    if (selectedCourse) {
      const isAvail = coursesData.find(
        (course) => course.ID === parseInt(selectedCourse)
      );
      console.log(isAvail);
      if (isAvail && isAvail.ID) {
        return;
      }

      const resultCourse = courseOptions.find(
        (course) => course.ID === parseInt(selectedCourse)
      );
      coursesData.push(resultCourse);
      setCourses(coursesData);
      console.log(resultCourse);
    }
  }

  useEffect(() => {
    (async () => {
      fetchCategoryList();
      fetchCountryList();
      fetchCourseList({ limit: 100, page: 1 });
      if (cid) {
        await fetchData(cid);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = useLocation().search;
  const cid = new URLSearchParams(search).get("id");

  useEffect(() => {
    (async () => {
      validateForm();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCenterType, selectedCountry, courseName]);

  return (
    <>
      <ToastContainer />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => handleNav("/centre-management")}>
              Centre Management
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {cid ? "Edit" : "New"} Centre
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{cid ? "Edit" : "New"} Centre</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="courseName">
                      <Form.Label>Centre Name</Form.Label>
                      <InputGroup>
                        {/* <InputGroup.Text>C</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          value={courseName}
                          onChange={(e) => handleChange(e, "course_name")}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        disabled={centerTypeOptions.length === 0}
                        id="state"
                        value={selectedCenterType}
                        onChange={(e) => handleChange(e, "centerType")}
                      >
                        <option value="0" key={0}>
                          -- Select --
                        </option>
                        {centerTypeOptions.map((data) => (
                          <option value={data.ID} key={data.ID}>
                            {data.type}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Country</Form.Label>
                      <Form.Select
                        disabled={countryOptions.length === 0}
                        id="state"
                        value={selectedCountry}
                        onChange={(e) => handleChange(e, "country")}
                      >
                        <option value="0" key={0}>
                          -- Select --
                        </option>
                        {countryOptions.map((data) => (
                          <option value={data.ID} key={data.ID}>
                            {data.country}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <hr />

                <h5 className="mb-1">Courses</h5>
                <Row className="align-items-center">
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Select
                        disabled={courseOptions.length === 0}
                        id="state"
                        value={selectedCourse}
                        onChange={(e) => handleChange(e, "course")}
                      >
                        <option value="0" key={0}>
                          -- Select --
                        </option>
                        {courseOptions.map((data) => (
                          <option value={data.ID} key={data.ID}>
                            {data.name + " - " + data.specification}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Button
                        disabled={selectedCourse === 0}
                        onClick={() => assignCourse()}
                        variant="warning"
                      >
                        Assign Course
                      </Button>
                    </Form.Group>
                  </Col>
                  <Row>
                    <Col sm={12} className="mb-3">
                      <Table
                        responsive
                        className="table-centered table-nowrap rounded mb-0"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th className="border-0">ID</th>
                            <th className="border-0">Course Name</th>
                            <th className="border-0">Specification</th>
                            <th className="border-0">Qualification Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {courses.map((r, index) => {
                            return (
                              !r.is_del && (
                                <tr key={index}>
                                  <td className="fw-bold border-0">{r.ID}</td>
                                  <td className="fw-bold border-0">{r.name}</td>
                                  <td className="border-0">
                                    {r.specification}
                                  </td>
                                  <td className="border-0">
                                    {r.qualification_number}
                                  </td>

                                  <td className="fw-bold border-0">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="text-danger"
                                    >
                                      <FontAwesomeIcon
                                        onClick={() => deleteModule(index)}
                                        icon={faTrashAlt}
                                        className="me-3 mt-3"
                                      />
                                    </span>
                                  </td>
                                </tr>
                              )
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Row>

                <div className="mt-3">
                  {/* {birthday} */}
                  <Button
                    disabled={!saveValid}
                    onClick={() => saveCentre()}
                    variant="primary"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

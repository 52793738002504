import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Modal,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";

import services from "../../services/index";

import { CertificateManagementTable } from "../../components/Tables";
import { ComponentToPrint } from "../../components/printreports/Cetificates";
import {
  CertificateView,
  CertificateAdd,
} from "../../components/certificate/ActionsCertificate";

export default () => {
  let history = useHistory();

  function handleNav(path) {
    history.push(path);
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableOptions, SetTableOptions] = useState({});
  const [popupType, setPopupType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedCertificateData, setSelectedCertificateData] = useState(null);

  const [showPopUp, setShowPopUp] = useState(false);
  const handleClose = () => setShowPopUp(false);
  const modalOverlayClick = () => null;

  useEffect(() => {
    (async () => {
      fetchData({ limit: 10, page: 1 });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!searchText) {
        fetchData({ limit: 10, page: 1 });
      }
    })();
  }, [searchText]);

  function handleChange(e, type, index = 0) {
    switch (type) {
      case "search":
        setSearchText(e && e.target?.value ? e.target.value : "");
        break;

      default:
        break;
    }
  }

  async function fetchData(params) {
    setLoading(true);

    if (searchText) {
      params.search = searchText;
    }

    const req = await services.certificateService.getCertificates(params);
    setRowData(req?.data?.data ? req.data.data : []);
    SetTableOptions(req?.data ? req.data : {});
    setLoading(false);
  }

  async function fetchCertificate(id) {
    const req = await services.certificateService.getCertificate(id);

    if (req?.data?.data?.certificate?.awarded_date) {
      var awardedDate = new Date(req.data.data.certificate.awarded_date);
      const awardedDateFormated = dateFormat(awardedDate, "d mmmm yyyy");
      req.data.data.certificate.awardedDateFormated = awardedDateFormated;

      var issueDate = new Date(req.data.data.certificate.issue_date);
      const issueDateFormated = dateFormat(issueDate, "d mmmm yyyy");
      req.data.data.certificate.issueDateFormated = issueDateFormated;

      let totCredits = 0;
      let totECTS = 0;
      let unitMarksArray = req.data.data.unitmarks;
      unitMarksArray.forEach((element) => {
        totCredits +=
          element.ects && element.moduleunit ? element.moduleunit.credits : 0;
        totECTS += element.ects ? element.ects : 0;
      });

      req.data.data.certificate.total_credits = totCredits;
      req.data.data.certificate.total_ects = totECTS;
    }

    setSelectedCertificateData(req?.data?.data ? req.data.data : null);
  }

  function onPageChange(pagenumber) {
    if (pagenumber !== tableOptions.page) {
      fetchData({ limit: 10, page: pagenumber });
    }
  }

  function onRowView(id, type) {
    setPopupType(type);
    // setSelectedID(id);
    if (type === "View Certificate" && id) {
      setSelectedCertificateData(null);
      fetchCertificate(id);
    }

    if (type === "Edit Certificate" && id) {
      handleNav("/certificate-management/edit?id=" + id);
    }
    setShowPopUp(true);
  }

  function PopupComponents() {
    switch (popupType) {
      case "View Certificate":
        return (
          <CertificateView
            selectedCertificateData={selectedCertificateData}
            handlePrint={handlePrint}
          />
        );

      case "Add Certificate":
        return <CertificateAdd />;

      case "Edit Certificate":
        return <CertificateAdd />;

      default:
        return <CertificateView handlePrint={handlePrint} />;
    }
  }

  return (
    <>
      <React.Fragment>
        <Modal
          as={Modal.Dialog}
          centered
          show={showPopUp}
          size='xl'
          onHide={modalOverlayClick}
        >
          <Modal.Header>
            <Modal.Title className='h6'>{popupType}</Modal.Title>
            <Button variant='close' aria-label='Close' onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "none" }}>
              <ComponentToPrint
                data={selectedCertificateData}
                ref={componentRef}
              />
            </div>
            <PopupComponents />
          </Modal.Body>
        </Modal>
      </React.Fragment>

      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Certificate Management</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Certificate Management</h4>
        </div>
        <div className='btn-toolbar mb-2 mb-md-0'>
          <ButtonGroup>
            <Button
              onClick={() => handleNav("/certificate-management/new")}
              variant='primary'
              size='sm'
            >
              <FontAwesomeIcon icon={faPlus} /> New Certificate
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className='table-settings mb-4'>
        <Row className='justify-content-between align-items-center'>
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text></InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Learner ID or Certificate Number'
                onChange={(e) => handleChange(e, "search")}
                value={searchText}
              />
              <Button
                disabled={!searchText}
                onClick={() => fetchData({ limit: 10, page: 1 })}
                variant='primary'
                size='sm'
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className='ps-md-0 text-end'>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant='link'
                className='text-dark m-0 p-0'
              >
                <span className='icon icon-sm icon-gray'>
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className='dropdown-menu-xs dropdown-menu-right'>
                <Dropdown.Item className='fw-bold text-dark'>
                  Show
                </Dropdown.Item>
                <Dropdown.Item className='d-flex fw-bold'>
                  10{" "}
                  <span className='icon icon-small ms-auto'>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className='fw-bold'>20</Dropdown.Item>
                <Dropdown.Item className='fw-bold'>30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <CertificateManagementTable
        loading={loading}
        rowdata={rowData}
        tableOptions={tableOptions}
        onPageChange={onPageChange}
        onRowView={onRowView}
      />
    </>
  );
};

import React from "react";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faExternalLinkAlt,
  faEye,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import edukLogoFull from "../../assets/img/certificate/eduk_logo_full.svg";
// import cerSig from "../../assets/img/certificate/cer_sig.svg";
// import ukRlp from "../../assets/img/certificate/ukrlp.svg";
// import certQr from "../../assets/img/certificate/cert_qr.jpg";

export class ComponentToPrint extends React.PureComponent {
  render() {
    const TableRow = (props) => {
      const { moduleunit, ects, grade, type } = props;

      return (
        <tr className='d-flex'>
          <td className='border-0 text-left p-1 col-2'>
            {moduleunit?.unitref ? moduleunit.unitref : "-"}
          </td>
          <td className='border-0 text-uppercase text-left p-1 col-5 text-wrap'>
            {moduleunit?.title ? moduleunit.title : "-"}
          </td>
          <td className='border-0 text-center p-1 col-1'>
            {moduleunit?.level ? moduleunit.level : "-"}
          </td>
          <td
            className='border-0 text-center p-1 col-1'
            style={{ width: "80px" }}
          >
            {moduleunit?.credits ? moduleunit.credits : "-"}
          </td>
          <td className='border-0 text-center p-1 col-1'>
            {ects ? ects : "-"}
          </td>
          <td
            className='border-0 text-center p-1 col-1'
            style={{ width: "100px" }}
          >
            {grade ? grade : "-"}
          </td>
        </tr>
      );
    };

    console.log(this.props);
    return (
      <div
        className='print-container'
        style={{ margin: "0", padding: "0", backgroundColor: "white" }}
      >
        <div className='certi-logo-area text-center'>
          {/* <img
            className=" my-4 py-4"
            src={edukLogoFull}
            style={{
              width: "40%",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          /> */}
        </div>
        <div
          className='certi-content-area'
          style={{ marginLeft: "8%", marginRight: "8%", marginTop: "35%" }}
        >
          <h4
            className='text-uppercase fw-bolder'
            style={{ fontSize: "2.75rem" }}
          >
            {this.props.data?.certificate?.course?.name
              ? this.props.data.certificate.course.name
              : ""}
          </h4>
          <p style={{ marginTop: "4%" }}>This is to certify that</p>
          <h5
            className='text-uppercase fw-bold mb-3'
            style={{ fontSize: "1.5rem" }}
          >
            {this.props.data?.certificate?.student?.first_name
              ? this.props.data.certificate.student.first_name
              : ""}{" "}
            {this.props.data?.certificate?.student?.last_name
              ? this.props.data.certificate.student.last_name
              : ""}
          </h5>
          has successfully completed a regulated qualification at
          <p
            className='text-uppercase fw-bold mt-3'
            style={{
              marginBottom: "0rem",
              fontSize: "1.25rem",
            }}
          >
            {this.props.data?.certificate?.centre?.name
              ? this.props.data.certificate.centre.name
              : ""}
          </p>
          <p
            className='fw-bold'
            style={{
              marginTop: "0.5rem",
              marginBottom: "0.8rem",
              fontSize: "1rem",
            }}
          >
            Awarded on{" "}
            {this.props.data?.certificate?.awardedDateFormated
              ? this.props.data.certificate.awardedDateFormated
              : ""}
          </p>
          <p className='fw-light' style={{ fontSize: "0.875rem" }}>
            {/* EUROPEAN QUALIFICATION FRAMEWORL(EQF) LEVEL{" "}
            {this.props.data?.certificate?.course?.level
              ? this.props.data.certificate.course.level
              : ""} */}
            <br />C
            {this.props.data?.certificate?.certificate_number
              ? this.props.data.certificate.certificate_number
              : ""}{" "}
            |{" "}
            {this.props.data?.certificate?.course?.qualification_number
              ? this.props.data.certificate.course.qualification_number
              : ""}{" "}
            |{" "}
            {this.props.data?.certificate?.student?.learner_id
              ? this.props.data.certificate.student.learner_id
              : ""}{" "}
            | ISSUED ON{" "}
            <span className='text-uppercase'>
              {this.props.data?.certificate?.issueDateFormated
                ? this.props.data.certificate.issueDateFormated
                : ""}
            </span>
            <br />
            THIS CERTIFICATE SHOULD BE READ IN CONJUNCTION WITH THE ACCOMPANYING
            TRANSCRIPT
            <br />
            This certificate can be verified online at <b>https://portal.eduk.org.uk</b>
          </p>
        </div>

        <div className='page-break' />
        <div className='row'>
          <div className='col-6 ps-5'></div>
          <div className='col-6 text-end pe-5'>
            {/* <img
              className=" pt-4"
              src={edukLogoFull}
              style={{
                width: "60%",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            /> */}
          </div>
        </div>
        <div
          className='certi-content-area'
          style={{ marginLeft: "8%", marginRight: "8%", paddingTop: "20%" }}
        >
          <h2
            className='fw-bold'
            style={{
              marginTop: "0rem",
              marginBottom: "0.8rem",
            }}
          >
            TRANSCRIPT
          </h2>
          <h4
            className='text-uppercase fw-bold'
            style={{ marginTop: "0rem", marginBottom: "0.3rem" }}
          >
            {this.props.data?.certificate?.course?.name
              ? this.props.data.certificate.course.name
              : ""}
          </h4>
          <p style={{ marginTop: "0%" }}>
            This is to certify that
            <h5 className='text-uppercase fw-bold my-1'>
              {this.props.data?.certificate?.student?.first_name
                ? this.props.data.certificate.student.first_name
                : ""}{" "}
              {this.props.data?.certificate?.student?.last_name
                ? this.props.data.certificate.student.last_name
                : ""}
            </h5>
            has successfully completed a regulated qualification at
          </p>

          <h6
            className='text-uppercase fw-bold mb-1'
            style={{
              marginTop: "0rem",
              fontSize: "1.1rem",
            }}
          >
            {this.props.data?.certificate?.centre?.name
              ? this.props.data.certificate.centre.name
              : ""}
          </h6>
          <p
            className='fw-light'
            style={{ marginTop: "0rem", marginBottom: "0.5rem" }}
          >
            Awarded on{" "}
            {this.props.data?.certificate?.awardedDateFormated
              ? this.props.data.certificate.awardedDateFormated
              : ""}
          </p>
          <p>
            The learner has achieved the required standards in the following
            unit(s).
          </p>
          <div className='table-wrapper border-light'>
            <table className='user-table align-items-center table table-responsive table-hover'>
              <thead
                style={{
                  textAlign: "left",
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                <tr
                  className='d-flex'
                  style={{
                    textAlign: "left",
                  }}
                >
                  <th
                    className='border-0 text-start ps-1 py-1 col-2'
                    style={{ fontSize: "0.9rem" }}
                  >
                    Unit Ref.
                  </th>
                  <th
                    className='border-0 text-start ps-1 py-1 col-5'
                    style={{
                      fontSize: "0.9rem",
                    }}
                  >
                    Unit Title
                  </th>
                  <th
                    className='border-0 text-center  py-1 col-1'
                    style={{ fontSize: "0.9rem" }}
                  >
                    Level
                  </th>
                  <th
                    className='border-0 text-center py-1 col-1'
                    style={{ fontSize: "0.9rem", width: "80px" }}
                  >
                    Credits
                  </th>
                  <th
                    className='border-0 border-top-2 py-1 text-center col-1'
                    style={{ fontSize: "0.9rem" }}
                  >
                    ECTS
                  </th>
                  <th
                    className='border-0 text-center py-1 col-1'
                    style={{ fontSize: "0.9rem", width: "100px" }}
                  >
                    Grade
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.data?.unitmarks ? (
                  this.props.data.unitmarks.map((r) => (
                    r.ects ? <TableRow key={`certi-${r.ID}`} {...r} /> : ""
                  ))
                ) : (
                  <tr>
                    <td rowSpan={5}>NO DATA</td>
                  </tr>
                )}
              </tbody>
              <tfoot
                style={{
                  textAlign: "left",
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                <tr className='d-flex'>
                  <td
                    className='border-0 fw-bolder col py-1'
                    colSpan='3'
                    style={{ fontSize: "medium" }}
                  >
                    Total credits achieved:{" "}
                    {this.props.data?.certificate?.total_credits
                      ? this.props.data.certificate.total_credits
                      : ""}
                  </td>
                  <td
                    className='border-0 fw-bolder col text-right py-1'
                    colSpan='2'
                    style={{ fontSize: "medium" }}
                  >
                    Total ECTS credits achieved:{" "}
                    {this.props.data?.certificate?.total_ects
                      ? this.props.data.certificate.total_ects
                      : ""}{" "}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className='row'>
            <section style={{ width: "50%", float: "left" }}>
              <table>
                <tbody>
                  <tr>
                    <td className='px-2'>Grading Type</td>
                    <td className='px-2'>
                      {" : "}
                      {this.props.data?.certificate?.course?.gradetype?.type
                        ? this.props.data.certificate.course.gradetype?.type
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className='px-2'>Language Of Asssesment</td>
                    <td className='px-2'>
                      {" : "}
                      {this.props.data?.certificate?.course?.language?.name
                        ? this.props.data.certificate.course.language?.name
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section style={{ width: "50%", float: "left" }}>
              <table style={{ float: "right" }}>
                <tbody>
                  <tr>
                    <td className='px-2'>Qualification Number</td>
                    <td className='px-2'>
                      {" : "}
                      {this.props.data?.certificate?.course
                        ?.qualification_number
                        ? this.props.data.certificate.course
                            .qualification_number
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className='px-2'>Learner Number</td>
                    <td className='px-2'>
                      {" : "}
                      {this.props.data?.certificate?.student?.learner_id
                        ? this.props.data.certificate.student.learner_id
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className='px-2'>Certificate Number</td>
                    <td className='px-2'>
                      {" : C"}
                      {this.props.data?.certificate?.certificate_number
                        ? this.props.data.certificate.certificate_number
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
          <div className='row'>
            <div className='col-12'>
              <p className='fw-bolder m-1'>
                Issued on{" "}
                <span>
                  {this.props.data?.certificate?.issueDateFormated
                    ? this.props.data.certificate.issueDateFormated
                    : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

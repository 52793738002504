
import React, {  useEffect } from "react";
import { Col, Row, Card, Form, Table } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faCog, faHome, faSearch, faPrint } from '@fortawesome/free-solid-svg-icons';

export const CertificateView = ({selectedCertificateData = {}, handlePrint}) => {  
  useEffect(() => {  
    
    // console.log("hhehrhehheh");
  }, [])

  const TableRow = (props) => {
    const { moduleunit, ects, grade} = props;

    return (
      <tr>
        <td className="fw-bold border-0">
          {moduleunit?.unitref ? moduleunit.unitref : "-"}
        </td>
        <td className="border-0">
          {moduleunit?.title ? moduleunit.title : "-"}
        </td>
        <td className="fw-bold border-0">
          {moduleunit?.level ? moduleunit.level : "-"}
        </td>
        <td className="border-0">
          {moduleunit?.credits ? moduleunit.credits : "-"}
        </td>
        <td className="fw-bold border-0">
          {ects ? ects : "-"}
        </td>
        <td className="border-0 fw-bold">
          {grade ? grade : "-"}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <button className="btn btn-primary btn-sm mb-1" onClick={handlePrint}><FontAwesomeIcon icon={faPrint} /> Print</button>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h4 className="mb-2">Certificate Information</h4>
        <Form>
          <Row>
            <Col md={3} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label className="mb-0">CERTIFICATE NUMBER</Form.Label>
                {/* <Form.Control required type="text" placeholder="Enter your first name" /> */}
                <h5>{selectedCertificateData?.certificate?.certificate_number ? selectedCertificateData.certificate.certificate_number : ""}</h5>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">LEARNER ID</Form.Label>
                <h5>{selectedCertificateData?.certificate?.student?.learner_id ? selectedCertificateData.certificate.student.learner_id : ""}</h5>
              </Form.Group>
            </Col>            
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">COUNTRY</Form.Label>
                <h5>{selectedCertificateData?.certificate?.student?.country?.country ? selectedCertificateData.certificate.student.country.country : ""}</h5>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">AWARD DATE</Form.Label>
                <h5>{selectedCertificateData?.certificate?.awardedDateFormated ? selectedCertificateData.certificate.awardedDateFormated : ""}</h5>
              </Form.Group>
            </Col>
          </Row>
          
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">COURSE</Form.Label>
                <h5>{selectedCertificateData?.certificate?.course?.name ? selectedCertificateData.certificate.course.name : ""}</h5>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label className="mb-0">CENTRE</Form.Label>
                <h5>{selectedCertificateData?.certificate?.centre?.name ? selectedCertificateData.certificate.centre.name : ""}</h5>
              </Form.Group>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md={12} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label className="mb-0">NAME</Form.Label>
                <h5>{selectedCertificateData?.certificate?.student?.first_name ? selectedCertificateData.certificate.student.first_name : ""} {selectedCertificateData?.certificate?.student?.last_name ? selectedCertificateData.certificate.student.last_name : ""}</h5>
              </Form.Group>
            </Col>
          </Row>

          <h4 className="my-2">Unit Marks</h4>
          <Row>
            <Col sm={12} className="mb-3">
              <Table responsive className="table-centered table-nowrap rounded mb-0">
                <thead className="thead-light">
                  <tr>
                    <th className="border-0">Unit Ref</th>
                    <th className="border-0">Unit Title</th>
                    <th className="border-0">Level</th>
                    <th className="border-0">Credits</th>
                    <th className="border-0">ETCS</th>
                    <th className="border-0">Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCertificateData?.unitmarks ? selectedCertificateData.unitmarks.map(r => <TableRow key={`certipop-${r.ID}`} {...r} />) : <tr><td rowSpan={5}>NO DATA</td></tr>}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
    </div> 
  );
};

export const CertificateAdd = ({rowdata = [], handlePrint}) => {  

  useEffect(() => {  
    
    // console.log("ADDDDDD");
  }, [])

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <h1>ADDD</h1>
      </Card.Body>
    </Card>
  );
};

import React, { useState, useEffect }  from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import BgImage from "../assets/img/illustrations/signin.svg";

import services from '../services/index';


export default () => {
  let history = useHistory();
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [btnDisable, setbtnDisable] = useState(true); 

  async function fetchAuth() {  
    try {
      let params = {
          "username": username,
          "password": password
      }
      const req = await services.authService.postAuth(params);
      toast.success("Successfully Logged In")
      if (req.data.data) {
        localStorage.setItem("token", req.data.data)
        history.push('/');
      }
    } catch (error) {
      error.response.status === 401 ? toast.error("Incorrect Credentials!") : toast.error("Something Went Wrong!");
    }      
  } 

  useEffect(() => {     
    validation();  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password])

  function handleChange(type, e) {
    switch (type) {
      case "username":
        setUsername(e.target.value);
        break;

      case "password":
        setPassword(e.target.value)
        break;
    
      default:
        break;
    }       
  }

  function validation() {  
    if (username && password) {
      setbtnDisable(false)
      return true;
    }
    setbtnDisable(true)
    return false;
  }

  return (
    <main>
      <ToastContainer />
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="username" className="mb-4">
                    <Form.Label>Your Username</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control value={username} onChange={(e) => handleChange("username", e)} autoFocus required type="text" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control value={password} onChange={(e) => handleChange("password", e)} required type="password" placeholder="Password" />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                      {/* <Card.Link className="small text-end">Lost password?</Card.Link> */}
                    </div>
                  </Form.Group>
                  <Button disabled={btnDisable} onClick={() => fetchAuth()} variant="primary" className="w-100">
                    Sign in
                  </Button>
                </Form>

                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div> */}
                {/* <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";

import services from "../../services/index";

import { CentreManagementTable } from "../../components/Tables";

export default () => {
  let history = useHistory();

  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableOptions, SetTableOptions] = useState({});

  function handleNav(path) {
    history.push(path);
  }

  useEffect(() => {
    (async () => {
      fetchData({ limit: 10, page: 1 });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!searchText) {
        fetchData({ limit: 10, page: 1 });
      }
    })();
  }, [searchText]);

  async function fetchData(params) {
    setLoading(true);

    if (searchText) {
      params.search = searchText;
    }

    const req = await services.centreService.getCentres(params);
    setRowData(req?.data?.data ? req.data.data : []);
    SetTableOptions(req?.data ? req.data : {});
    setLoading(false);
  }

  function onPageChange(pagenumber) {
    if (pagenumber !== tableOptions.page) {
      fetchData({ limit: 10, page: pagenumber });
    }
  }

  function onRowView(id, type) {
    if (type === "Edit Centre" && id) {
      handleNav("/centre-management/edit?id=" + id);
    }
  }

  function handleChange(e, type, index = 0) {
    switch (type) {
      case "search":
        setSearchText(e && e.target?.value ? e.target.value : "");
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Centre Management</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Centre Management</h4>
          {/* <div>{JSON.stringify(rowData)}</div> */}
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button
              onClick={() => handleNav("/centre-management/new")}
              variant="primary"
              size="sm"
            >
              <FontAwesomeIcon icon={faPlus} /> New Centre
            </Button>
            {/* <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button> */}
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text></InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Centre Name"
                onChange={(e) => handleChange(e, "search")}
                value={searchText}
              />
              <Button
                disabled={!searchText}
                onClick={() => fetchData({ limit: 10, page: 1 })}
                variant="primary"
                size="sm"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <CentreManagementTable
        loading={loading}
        rowdata={rowData}
        tableOptions={tableOptions}
        onPageChange={onPageChange}
        onRowView={onRowView}
      />
    </>
  );
};

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { faHome, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Table,
  Card,
  Button,
  Form,
  Breadcrumb,
  InputGroup,
} from "@themesberg/react-bootstrap";

import services from "../../services/index";

export default () => {
  let history = useHistory();

  function handleNav(path) {
    history.push(path);
  }

  const [courseName, setCourseName] = useState("");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [moduleUnits, setModuleUnits] = useState([]);
  const [qualificationNumber, setQualificationNumber] = useState("");
  const [specification, setSpecification] = useState("");
  const [level, setLevel] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [saveValid, setSaveValid] = useState(false);
  const [updateID, setUpdateID] = useState(null);

  function handleChange(e, type, index = 0) {
    switch (type) {
      case "qualification_number":
        let qualification_number = e && e.target?.value ? e.target.value : "";
        setQualificationNumber(qualification_number);
        break;

      case "specification":
        let specification = e && e.target?.value ? e.target.value : "";
        setSpecification(specification);
        break;

      case "level":
        let level = e && e.target?.value ? e.target.value : "";
        setLevel(level);
        break;

      case "category":
        let selectedCategoryID = e && e.target?.value ? e.target.value : 0;
        const result = categoriesOptions.find(
          (category) => category.ID === parseInt(selectedCategoryID)
        );

        if (result) {
          setSelectedCategory(selectedCategoryID);
        } else {
          setSelectedCategory(0);
        }
        break;

      case "course_name":
        let course_name = e && e.target?.value ? e.target.value : "";
        setCourseName(course_name);
        break;

      default:
        break;
    }
  }

  function validateForm() {
    let formsValid = false;
    formsValid =
      courseName && selectedCategory && specification && qualificationNumber
        ? true
        : false;
    setSaveValid(formsValid);
    return formsValid;
  }

  function validateModules() {
    let modulesValid = true;
    for (let index = 0; index < moduleUnits.length; index++) {
      const element = moduleUnits[index];
      let valid =
        element.unitref &&
        element.title &&
        element.title &&
        element.level &&
        element.credits &&
        element.glh
          ? true
          : false;

      if (!valid) {
        modulesValid = false;
        break;
      }
    }
    return modulesValid;
  }

  async function saveCertificate() {
    try {
      let status = validateForm();

      if (!status) {
        return;
      }

      let data = {
        name: courseName,
        qualification_number: qualificationNumber,
        specification: specification,
        level: parseInt(level),
        gradetype_id: 1,
        language_id: 1,
        category_id: parseInt(selectedCategory),
        status: 1,
        moduleunits: moduleUnits,
      };

      if (cid) {
        data.ID = updateID;

        const req = await services.courseService.putCourse(data);
        if (req) {
          toast.success("Course updated");
        }
      } else {
        const req = await services.courseService.postCreateCourseWithMoudles(
          data
        );
        if (req) {
          toast.success("Course Created");
          history.push("/course-management");
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  }

  async function fetchCategoryList(params) {
    const req = await services.commonService.getCategoryList();
    setCategoriesOptions(req?.data?.data ? req.data.data : "");
  }

  async function fetchData(id) {
    const req = await services.courseService.getCourse(id);
    if (req?.data?.data) {
      let res = req.data.data;
      console.log(res);

      setCourseName(res?.name ? res.name : "");
      setSelectedCategory(res?.category_id ? res.category_id : 0);
      setQualificationNumber(
        res?.qualification_number ? res.qualification_number : ""
      );
      setSpecification(res?.specification ? res.specification : "");
      setLevel(res?.level ? res.level : "");
      setUpdateID(res?.ID ? res.ID : null);
      setModuleUnits(res?.moduleunits ? res.moduleunits : []);
    }
  }

  const details_handler = (event, index) => {
    const items = [...moduleUnits];
    console.log(event.target.type);
    items[index][event.target.name] =
      event.target.type === "number"
        ? parseInt(event.target.value)
        : event.target.value;
    console.log(items);
    setModuleUnits(items);
  };

  function deleteModule(index) {
    const items = [...moduleUnits];
    if (items[index] && items[index].ID) {
      items[index].is_del = 1;
    } else {
      items.splice(index, 1);
    }
    setModuleUnits(items);
  }

  function addNewModule() {
    const items = [...moduleUnits];
    items.push({
      unitref: "",
      title: "",
      level: 0,
      credits: 0,
      glh: 0,
      tqt: 0,
    });
    setModuleUnits(items);
  }

  useEffect(() => {
    (async () => {
      fetchCategoryList();
      if (cid) {
        await fetchData(cid);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = useLocation().search;
  const cid = new URLSearchParams(search).get("id");

  useEffect(() => {
    (async () => {
      validateForm();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    level,
    specification,
    qualificationNumber,
    selectedCategory,
    categoriesOptions,
    courseName,
  ]);

  return (
    <>
      <ToastContainer />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => handleNav("/course-management")}>
              Course Management
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {cid ? "Edit" : "New"} Course
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{cid ? "Edit" : "New"} Course</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="courseName">
                      <Form.Label>Course Name</Form.Label>
                      <InputGroup>
                        {/* <InputGroup.Text>C</InputGroup.Text> */}
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          value={courseName}
                          onChange={(e) => handleChange(e, "course_name")}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        disabled={categoriesOptions.length === 0}
                        id="state"
                        value={selectedCategory}
                        onChange={(e) => handleChange(e, "category")}
                      >
                        <option value="0" key={0}>
                          -- Select --
                        </option>
                        {categoriesOptions.map((data) => (
                          <option value={data.ID} key={data.ID}>
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6} className="mb-3">
                    <Form.Group id="courseName">
                      <Form.Label>Qualification Number</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="XXX/XXX/X"
                          value={qualificationNumber}
                          onChange={(e) =>
                            handleChange(e, "qualification_number")
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="courseName">
                      <Form.Label>Specification</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="May XXXX"
                          value={specification}
                          onChange={(e) => handleChange(e, "specification")}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="courseName">
                      <Form.Label>Level</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          placeholder="Leven Number"
                          value={level}
                          onChange={(e) => handleChange(e, "level")}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <h5 className="my-4">Unit Marks</h5>
                <Row>
                  <Col sm={12} className="mb-3">
                    <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">Unit Ref</th>
                          <th className="border-0">Unit Title</th>
                          <th className="border-0">Level</th>
                          <th className="border-0">Credits</th>
                          <th className="border-0">GLH</th>
                          <th className="border-0">TQT</th>
                          <th className="border-0">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {moduleUnits.map((r, index) => {
                          return (
                            !r.is_del && (
                              <tr key={index}>
                                <td className="fw-bold border-0">
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        placeholder="Unit Ref"
                                        key={index}
                                        name="unitref"
                                        value={r.unitref}
                                        onChange={(e) =>
                                          details_handler(e, index)
                                        }
                                        style={{ width: "50px" }}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </td>
                                <td className="border-0">
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        placeholder="Title"
                                        key={index}
                                        name="title"
                                        value={r.title}
                                        onChange={(e) =>
                                          details_handler(e, index)
                                        }
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </td>
                                <td className="border-0">
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="number"
                                        placeholder="Level"
                                        key={index}
                                        name="level"
                                        value={r.level}
                                        onChange={(e) =>
                                          details_handler(e, index)
                                        }
                                        style={{ width: "40px" }}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </td>
                                <td className="border-0">
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="number"
                                        placeholder="Credits"
                                        key={index}
                                        name="credits"
                                        value={r.credits}
                                        onChange={(e) =>
                                          details_handler(e, index)
                                        }
                                        style={{ width: "50px" }}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </td>
                                <td className="fw-bold border-0">
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="number"
                                        placeholder="GLH"
                                        key={index}
                                        name="glh"
                                        value={r.glh}
                                        onChange={(e) =>
                                          details_handler(e, index)
                                        }
                                        style={{ width: "50px" }}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </td>
                                <td className="fw-bold border-0">
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="number"
                                        placeholder="TQT"
                                        key={index}
                                        name="tqt"
                                        value={r.tqt}
                                        onChange={(e) =>
                                          details_handler(e, index)
                                        }
                                        style={{ width: "50px" }}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </td>
                                <td className="fw-bold border-0">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="text-danger"
                                  >
                                    <FontAwesomeIcon
                                      onClick={() => deleteModule(index)}
                                      icon={faTrashAlt}
                                      className="me-3 mt-3"
                                    />
                                  </span>
                                </td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Button
                  disabled={!validateModules()}
                  onClick={() => addNewModule()}
                  variant="outline-primary"
                  style={{ width: "150px", float: "right" }}
                  className="m-1"
                >
                  Add New Module
                </Button>
                <div className="mt-4">
                  {/* {birthday} */}
                  <Button
                    disabled={!saveValid}
                    onClick={() => saveCertificate()}
                    variant="primary"
                  >
                    Save Course
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

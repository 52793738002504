import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { faHome, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Button,
  Form,
  Breadcrumb,
  InputGroup,
  Table,
} from "@themesberg/react-bootstrap";
import AsyncSelect from "react-select/async";

import services from "../../services/index";

export default () => {
  let history = useHistory();
  const selectStudentRef = useRef();
  const selectCourseRef = useRef();

  function handleNav(path) {
    history.push(path);
  }

  const [awardDate, setAwardDate] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");
  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedCentre, setSelectedCentre] = useState(null);
  const [selectedCentreObj, setSelectedCentreObj] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedStudentObj, setSelectedStudentObj] = useState(null);
  const [selectedCourseObj, setSelectedCourseObj] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [moduleUnits, setModuleUnits] = useState([]);
  const [totalECTS, setTotalECTS] = useState(0);
  const [saveValid, setSaveValid] = useState(false);

  let centerOptions = [];

  const filterSelectionData = (inputValue, data) => {
    return data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseCentreOptions = async (inputValue) => {
    try {
      let params = { limit: 10, page: 1, search: inputValue };
      const req = await services.centreService.getCentres(params);
      let resData = req?.data?.data ? req.data.data : [];
      centerOptions = [];
      resData.forEach((element) => {
        centerOptions.push({ value: element.ID, label: element.name });
      });
      return filterSelectionData(inputValue, centerOptions);
    } catch (e) {
      return [];
    }
  };

  let promiseStudentOptions = async (inputValue) => {
    try {
      if (selectedCentre) {
        let params = {
          limit: 10,
          page: 1,
          search: inputValue,
          centre_id: selectedCentre,
        };
        const req = await services.studentService.getStudentsByCentreID(params);
        let resData = req?.data?.data ? req.data.data : [];

        let options = [];
        resData.forEach((element) => {
          options.push({
            value: element.ID,
            label:
              element.learner_id +
              " - " +
              element.first_name +
              " " +
              element.last_name,
            courses: element.courses,
          });
        });
        return filterSelectionData(inputValue, options);
      }

      return null;
    } catch (e) {
      return null;
    }
  };

  async function getStudentOptions(centre_id) {
    let params = {
      limit: 10,
      page: 1,
      search: "",
      centre_id: centre_id,
    };
    const req = await services.studentService.getStudentsByCentreID(params);
    let resData = req?.data?.data ? req.data.data : [];

    let options = [];
    resData.forEach((element) => {
      options.push({
        value: element.ID,
        label:
          element.learner_id +
          " - " +
          element.first_name +
          " " +
          element.last_name,
        courses: element.courses,
      });
    });
    return filterSelectionData("", options);
  }
  let promiseGetCourseOptions = async (inputValue) => {
    let options = [];
    courseOptions.forEach((element) => {
      options.push({
        value: element.ID,
        label:
          element.name +
          " - " +
          element.specification +
          " " +
          element.qualification_number,
      });
    });
    return filterSelectionData(inputValue, options);
  };

  function handleChange(e, type, index = 0) {
    switch (type) {
      case "centre":
        selectStudentRef.current.clearValue();
        setSelectedCentreObj(e);
        setSelectedCentre(e && e.value ? e.value : "");
        setSelectedCourse(0);
        break;
      case "student":
        setSelectedStudentObj(e);
        setSelectedStudent(e && e.value ? e.value : "");
        setCourseOptions(e && e.courses ? e.courses : []);
        setSelectedCourse(0);
        setSelectedCourseObj({});
        setModuleUnits([]);
        break;

      case "course":
        setSelectedCourseObj(e);
        let selectedCourseID = e && e.value ? e.value : 0;
        const result = courseOptions.find(
          (course) => course.ID === parseInt(selectedCourseID)
        );

        if (result) {
          result.moduleunits.forEach((element) => {
            element.ects = 0;
            element.grade = "PASS";
          });
          console.log(result?.moduleunits);
          setModuleUnits(result.moduleunits);
          setSelectedCourse(selectedCourseID);
        } else {
          setSelectedCourse(0);
          setModuleUnits([]);
        }
        break;

      case "award_date":
        setAwardDate(e._d);
        console.log(awardDate);
        break;

      case "issue_date":
        setIssueDate(e._d);
        console.log(issueDate);
        break;

      case "certificate_number":
        let certificate_number = e && e.target?.value ? e.target.value : 0;
        setCertificateNumber(certificate_number);
        break;

      default:
        break;
    }
  }

  const details_handler = (event, index) => {
    const items = [...moduleUnits];
    items[index][event.target.name] = event.target.value;
    console.log(items);
    setModuleUnits(items);
    calTotalECTS();
  };

  function validateForm() {
    let formsValid = false;
    formsValid =
      certificateNumber &&
      selectedCourse &&
      selectedStudent &&
      selectedCentre &&
      awardDate !== "" &&
      issueDate !== "" &&
      totalECTS !== 0
        ? true
        : false;
    setSaveValid(formsValid);
    return formsValid;
  }

  async function saveCertificate() {
    try {
      let status = validateForm();
      if (!status) {
        return;
      }
      var momentDate = new moment(awardDate);
      var awardedDateFormated = momentDate.format("YYYY-MM-DD HH:mm:ss");
      const awardedDate = new Date(awardedDateFormated);
      let unitMarksData = [];

      if (cid) {
        moduleUnits.forEach((element) => {
          console.log(element);
          unitMarksData.push({
            ID: element.unitmark_id,
            student_id: selectedStudent,
            certificate_id: 0,
            course_id: parseInt(selectedCourse),
            moduleunit_id: element.ID,
            ects: parseFloat(element.ects),
            grade: element.grade ? element.grade : "PASS",
            status: 1,
          });
        });

        let data = {
          certificate: {
            ID: parseInt(cid),
            awarded_date: awardedDate,
            issue_date: issueDate,
            certificate_number: parseInt(certificateNumber),
            student_id: selectedStudent,
            country_id: 1,
            centre_id: selectedCentre,
            course_id: parseInt(selectedCourse),
            status: 1,
          },
          unitmarks: unitMarksData,
        };

        const req = await services.certificateService.putCertificateWithMarks(
          data
        );
        req && toast.success("Certificate Updated");
      } else {
        moduleUnits.forEach((element) => {
          unitMarksData.push({
            student_id: selectedStudent,
            certificate_id: 0,
            course_id: parseInt(selectedCourse),
            moduleunit_id: element.ID,
            ects: parseFloat(element.ects),
            grade: element.grade,
            status: 1,
          });
        });

        let data = {
          certificate: {
            awarded_date: awardedDate,
            issue_date: issueDate,
            certificate_number: parseInt(certificateNumber),
            student_id: selectedStudent,
            country_id: 1,
            centre_id: selectedCentre,
            course_id: parseInt(selectedCourse),
            status: 1,
          },
          unitmarks: unitMarksData,
        };

        const req = await services.certificateService.postCertificateWithMarks(
          data
        );
        if (req) {
          toast.success("Certificate Created");
          history.push("/certificate-management");
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  }

  async function fetchMaxCertificateNumber(params) {
    const req = await services.certificateService.getMaxCertificateNumber();
    setCertificateNumber(req?.data?.data ? req.data.data : "");
  }

  useEffect(() => {
    (async () => {
      if (cid) {
        await fetchData(cid);
      } else {
        fetchMaxCertificateNumber();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = useLocation().search;
  const cid = new URLSearchParams(search).get("id");

  useEffect(() => {
    (async () => {
      calTotalECTS();
      validateForm();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalECTS,
    moduleUnits,
    selectedCentre,
    selectedCourse,
    selectedStudent,
    courseOptions,
    awardDate,
    certificateNumber,
  ]);

  async function fetchData(id) {
    const req = await services.certificateService.getCertificate(id);
    if (req?.data?.data) {
      let res = req.data.data;
      let unitmarks = res?.unitmarks ? res.unitmarks : [];
      console.log(req);
      setCertificateNumber(res.certificate.certificate_number);
      setAwardDate(res.certificate.awarded_date);
      setIssueDate(res.certificate.issue_date);

      setSelectedCentre(res.certificate.centre_id);

      setSelectedCentreObj({
        value: res.certificate.centre.ID,
        label: res.certificate.centre.name,
      });

      setSelectedStudent(res.certificate.student_id);
      setSelectedStudentObj({
        value: res.certificate.student.ID,
        label:
          res.certificate.student.learner_id +
          " - " +
          res.certificate.student.first_name +
          " " +
          res.certificate.student.last_name,
      });

      setSelectedCourseObj({
        value: res.certificate.course.ID,
        label:
          res.certificate.course.name +
          " - " +
          res.certificate.course.specification +
          " " +
          res.certificate.course.qualification_number,
      });

      setSelectedCourse(res.certificate.course_id);
      // setSelectedCourseObj({ value: res.certificate.course.ID, label: res.certificate.course.name })
      // setCourseOptions([res.certificate.course])
      let resultStundents = await getStudentOptions(res.certificate.centre_id);
      console.log(resultStundents);
      var outputCourseOptions = resultStundents.filter(
        (resultStundent) => resultStundent.value === res.certificate.student_id
      );
      console.log(outputCourseOptions);
      setCourseOptions(
        outputCourseOptions.length > 0 ? outputCourseOptions[0].courses : []
      );

      if (outputCourseOptions.length > 0) {
        const result = outputCourseOptions[0].courses.find(
          (course) => course.ID === res.certificate.course.ID
        );

        if (result) {
          result.moduleunits.forEach((element) => {
            unitmarks.forEach((unitmark) => {
              if (unitmark.moduleunit_id === element.ID) {
                element.unitmark_id = unitmark.ID;
                element.ects = unitmark.ects;
                element.grade = unitmark.grade;
              }
            });
          });
          console.log(result?.moduleunits);
          setModuleUnits(result.moduleunits);
          setSelectedCourse(res.certificate.course.ID);
        } else {
          setSelectedCourse(0);
          setModuleUnits([]);
        }
      }
    }
  }

  function calTotalECTS() {
    let total = 0.0;
    if (moduleUnits.length > 0) {
      let allFilled = true;
      for (let indexETC = 0; indexETC < moduleUnits.length; indexETC++) {
        const elementETC = moduleUnits[indexETC];
        allFilled = elementETC.ects ? true : false;
        total = (parseFloat(total) + parseFloat(elementETC.ects)).toFixed(1);
        console.log(total);
        if (allFilled) {
          setTotalECTS(total);
        }
      }
    } else {
      setTotalECTS(0);
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleNav("/certificate-management")}
            >
              Certificate Management
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {cid ? "Edit" : "New"} Certificate
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{cid ? "Edit" : "New"} Certificate</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="certificateNumber">
                      <Form.Label>Certificate Number</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>C</InputGroup.Text>
                        <Form.Control
                          type="number"
                          placeholder="Number"
                          value={certificateNumber}
                          onChange={(e) =>
                            handleChange(e, "certificate_number")
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="birthday">
                      <Form.Label>Award Date</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => handleChange(e, "award_date")}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                awardDate
                                  ? moment(awardDate).format("DD/MM/YYYY")
                                  : ""
                              }
                              placeholder="DD/MM/YYYY"
                              readOnly={true}
                              onFocus={openCalendar}
                              onChange={(e) => handleChange(e, "award_date")}
                            />
                          </InputGroup>
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Centre</Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={promiseCentreOptions}
                        value={selectedCentreObj}
                        onChange={(e) => handleChange(e, "centre")}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Student</Form.Label>
                      <AsyncSelect
                        ref={selectStudentRef}
                        cacheOptions={false}
                        loadOptions={promiseStudentOptions}
                        value={selectedStudentObj}
                        isClearable={true}
                        isDisabled={selectedCentre ? false : true}
                        onChange={(e) => handleChange(e, "student")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Course</Form.Label>
                      <AsyncSelect
                        ref={selectCourseRef}
                        cacheOptions={false}
                        loadOptions={promiseGetCourseOptions}
                        value={selectedCourseObj}
                        isClearable={true}
                        isDisabled={selectedStudent ? false : true}
                        onChange={(e) => handleChange(e, "course")}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Issue Date</Form.Label>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => handleChange(e, "issue_date")}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={
                                issueDate
                                  ? moment(issueDate).format("DD/MM/YYYY")
                                  : ""
                              }
                              placeholder="DD/MM/YYYY"
                              readOnly={true}
                              onFocus={openCalendar}
                              onChange={(e) => handleChange(e, "issue_date")}
                            />
                          </InputGroup>
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <h5 className="my-4">Unit Marks</h5>
                <Row>
                  <Col sm={12} className="mb-3">
                    <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">Unit Ref</th>
                          <th className="border-0">Unit Title</th>
                          <th className="border-0">Credits</th>
                          <th className="border-0">ETCS</th>
                          <th className="border-0">Grade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {moduleUnits.map((r, index) => {
                          return (
                            <tr key={index}>
                              <td className="fw-bold border-0">
                                {r.unitref ? r.unitref : "-"}
                              </td>
                              <td className="border-0">
                                {r.title ? r.title : "-"}
                              </td>
                              <td className="border-0">
                                {r.credits ? r.credits : "-"}
                              </td>
                              <td className="fw-bold border-0">
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      placeholder="Score"
                                      key={index}
                                      name="ects"
                                      value={r.ects}
                                      onChange={(e) =>
                                        details_handler(e, index)
                                      }
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </td>
                              <td className="border-0 fw-bold">
                                <Form.Group>
                                  <Form.Select
                                    defaultValue="PASS"
                                    name="grade"
                                    value={r.grade}
                                    onChange={(e) => details_handler(e, index)}
                                  >
                                    <option value="PASS" key={1}>
                                      PASS
                                    </option>
                                    <option value="FAIL" key={2}>
                                      FAIL
                                    </option>
                                  </Form.Select>
                                </Form.Group>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="mb-3 fw-bold">
                    Total ECTS : {totalECTS}
                  </Col>
                </Row>
                <div className="mt-3">
                  {/* {birthday} */}
                  <Button
                    disabled={!saveValid}
                    onClick={() => saveCertificate()}
                    variant="primary"
                  >
                    Save All
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
